import axios from 'axios';
import Btns from '../../components/btns/Btns';
import { useContext, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import Loading from '../../components/loading/Loading';
import AppContext from '../../context/AppContext';

export default function RulesEtika() {
    const [activeButton, setActiveButton] = useState(6);
    const { leng } = useContext(AppContext)
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state

    const buttons = [
        {
            id: 1,
            name_uz: 'Universitet haqida',
            name_ru: 'О университете',
            name_en: 'About University',
            link: '/about',
        },
        {
            id: 2,
            name_uz: 'Fakultetlar',
            name_ru: 'Факультеты',
            name_en: 'Faculties',
            link: '/department',
        },
        {
            id: 3,
            name_uz: 'Markaz va boʻlimlar',
            name_ru: 'Центры и Отделы',
            name_en: 'Centers and other departments',
            link: '/departments',
        },
        {
            id: 4,
            name_uz: 'Rektorat',
            name_ru: 'Ректорат',
            name_en: 'University Rectorate',
            link: '/rektorat',
        },
        {
            id: 5,
            name_uz: 'Universitet qoidalari',
            name_ru: 'Правила Университета',
            name_en: 'University Rules',
            link: '/rules',
        },
        {
            id: 6,
            name_uz: 'Axloq kodeksi',
            name_ru: 'Кодекс этики',
            name_en: 'Code of Ethics',
            link: '/rulesEtika',
        },
        {
            id: 7,
            name_uz: 'Universitet nizomi',
            name_ru: 'Устав Университета',
            name_en: 'University charter',
            link: '/regulation',
        }
    ];

    const getData = async () => {
        try {
            const response = await axios.get(`https://new.utu-ranch.uz/category/axloq-qoidasi/`);
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // Set loading to false after data is fetched or if an error occurs
        }
    }

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return <Loading />; // Render loading indicator while data is being fetched
    }

    return (
        <div className="rules">
            <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
            {
                data.map((item, index) => (
                    <p key={index} className='rulesmaintext' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item[`desc_${leng}`]) }}></p>
                ))
            }
        </div>
    );
}
