import React, { useContext, useEffect, useState } from 'react';
import './about_styles.css';
import '../../components/main_banner/MainBannerStyle.css';
import Btns from '../../components/btns/Btns';
import researchAndLearn from '../../image/researchAndLearn.gif';
import { Link } from 'react-router-dom';
import logoTop from '../../image/logoAnim/logotop2.svg';
import logoMain from '../../image/logoAnim/logomain.svg';
import DOMPurify from 'dompurify';
import axios from 'axios';
import AppContext from '../../context/AppContext';

export default function About() {

    // btn set
    const [activeButton, setActiveButton] = useState(1);

    const buttons = [
        {
            id: 1,
            name_uz: 'Universitet haqida',
            name_ru: 'О университете',
            name_en: 'About University',
            link: '/about',
        },
        {
            id: 2,
            name_uz: 'Fakultetlar',
            name_ru: 'Факультеты',
            name_en: 'Faculties',
            link: '/department',
        },
        {
            id: 3,
            name_uz: 'Markaz va boʻlimlar',
            name_ru: 'Центры и Отделы',
            name_en: 'Centers and other departments',
            link: '/departments',
        },
        {
            id: 4,
            name_uz: 'Rektorat',
            name_ru: 'Ректорат',
            name_en: 'University Rectorate',
            link: '/rektorat',
        },
        {
            id: 5,
            name_uz: 'Universitet qoidalari',
            name_ru: 'Правила Университета',
            name_en: 'University Rules',
            link: '/rules',
        },
        {
            id: 6,
            name_uz: 'Axloq kodeksi',
            name_ru: 'Кодекс этики',
            name_en: 'Code of Ethics',
            link: '/rulesEtika',
        },
        {
            id: 7,
            name_uz: 'Universitet nizomi',
            name_ru: 'Устав Университета',
            name_en: 'University charter',
            link: '/regulation',
        }
    ]

    const [aboutData, setAboutData] = useState([])

    const getAboutData = async () => {
        try {
            const response = await axios.get('https://new.utu-ranch.uz/category/obuniversitet/');
            setAboutData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        getAboutData();
    }, []);

    // lenguage

    const { leng } = useContext(AppContext)

    return (
        <div className="aboutPage">
            <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
            {
                aboutData.map((item, index) => (
                    <div key={index} className="aboutPage_main">
                        <div className="aboutPage_main_logo">
                            <img src={logoTop} alt="Logo Top" />
                            <img src={logoMain} alt="Logo Main" />
                        </div>
                        <div className="aboutPage_main_title">
                            <p>{leng == 'uz' ? "RANCH texnologiya universiteti" : leng == 'ru' ? "Технологический университет РАНЧ" : "Technological University RANCH"}</p>
                            <p>{leng == 'uz' ? "(rektor bayonoti)" : leng == 'ru' ? "(заявление ректора)" : "(rector's statement)"}</p>
                        </div>
                        <p className='aboutPage_info' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item[`desc_${leng}`]) }}></p>
                    </div>
                ))
            }
        </div>
    );
}
