import Btns from "../../components/btns/Btns";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AppContext from "../../context/AppContext";
import DOMPurify from "dompurify";


export default function DepartmentChil() {

    // btn set
    const [activeButton, setActiveButton] = useState(2);

    const buttons = [
        {
            id: 1,
            name_uz: 'Universitet haqida',
            name_ru: 'О университете',
            name_en: 'About University',
            link: '/about',
        },
        {
            id: 2,
            name_uz: 'Fakultetlar',
            name_ru: 'Факультеты',
            name_en: 'Faculties',
            link: '/department',
        },
        {
            id: 3,
            name_uz: 'Markaz va boʻlimlar',
            name_ru: 'Центры и Отделы',
            name_en: 'Centers and other departments',
            link: '/departments',
        },
        {
            id: 4,
            name_uz: 'Rektorat',
            name_ru: 'Ректорат',
            name_en: 'University Rectorate',
            link: '/rektorat',
        },
        {
            id: 5,
            name_uz: 'Universitet qoidalari',
            name_ru: 'Правила Университета',
            name_en: 'University Rules',
            link: '/rules',
        },
        {
            id: 6,
            name_uz: 'Axloq kodeksi',
            name_ru: 'Кодекс этики',
            name_en: 'Code of Ethics',
            link: '/rulesEtika',
        },
        {
            id: 7,
            name_uz: 'Universitet nizomi',
            name_ru: 'Устав Университета',
            name_en: 'University charter',
            link: '/regulation',
        }
    ]

    const { leng } = useContext(AppContext);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);

    // Obyekt ma'lumotlarini olish
    const getInfo = async () => {
        try {
            const response = await axios.get(`https://new.utu-ranch.uz/category/kafedrakontent/by-kafedra/${id}/`)
            setData(response.data)
        } catch (error) {
            console.error("Xatolik:", error);
        }
    }

    // Umumiy ma'lumotlarni olish
    const getData = async () => {
        try {
            const response = await axios.get(`https://new.utu-ranch.uz/category/kafedrashu/`)
            setData2(response.data)
        } catch (error) {
            console.error("Xatolik:", error);
        }
    }

    // Sahifa yuklanganida yoki `id` o'zgarganda `useEffect` bajariladi
    useEffect(() => {
        getData();
        getInfo();
    }, [id]) // `id` o'zgarganda `getInfo` qayta yuklanadi

    return (
        <div className="depChil">
            <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
            <div className="depChil_main">
                <div className="depChil_main_aside">
                    {
                        data2.map(item => (
                            <div key={item.id}>
                                <Link to={`/department/${item.id}`}><p>{item[`name_${leng}`]}</p></Link>
                                <ul>
                                    {
                                        item.children_kafedra.map(rr => (
                                            <Link key={rr.id} to={`/department/${rr.id}`}>
                                                <li>{rr[`name_${leng}`]}</li>
                                            </Link>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))
                    }
                </div>
                <div className="saddasd">

                {
                    data.map(item => (
                        <div className="depChil_main_infos" key={item.id}>
                            <div className="depChil_main_infos_userInfo">
                                <img src={item.image} alt="" />
                                <div className="depChil_main_infos_userInfo_infos">
                                    <label htmlFor="">{item[`lavozim_${leng}`]}:</label>
                                    <h2>{item.full_name}</h2>
                                    <label htmlFor="">{leng === 'uz' ? "Kontakt:" : leng === 'ru' ? "Контакты:" : "Contacts:"}</label>
                                    <p>{item.kontakt}</p>
                                    <label htmlFor="">{leng === 'uz' ? "Email:" : leng === 'ru' ? "Электронная почта:" : ""}</label>
                                    <p>{item.pochta}</p>
                                </div>
                            </div>
                            <p className='aboutPage_info' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item[`kafedra_malumotlari_${leng}`]) }}></p>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
    )
}